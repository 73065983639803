import { PureComponent } from "react"
import { connect } from "react-redux"
import {
  compose,
  find,
  assoc,
  map,
  propEq,
  curry,
  evolve,
  identity,
  memoizeWith,
  mergeLeft,
  path,
  pathOr,
  prop,
  forEach,
  any,
  values,
  isNil,
  reject,
  includes,
  assocPath,
} from "ramda"

import { sizer } from "@ninjaone/utils"
import {
  TitleGroup,
  Text,
  Tabs,
  Select,
  Tooltip,
  Switch,
  DataTable,
  AlertMessage,
  Link,
  Input,
  Body,
} from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { androidDelegatedScopesOverrides } from "js/includes/common/utils"
import {
  localizationKey,
  localized,
  isNotNil,
  reportErrorAndShowMessage,
  localizedWith,
  isAndroidMDMAppEnabled,
  ninjaReportError,
  showSuccessMessage,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

import {
  updatePolicyItem as _updatePolicyItem,
  deletePolicyItem as _deletePolicyItem,
  revertPolicySection as _revertPolicySection,
} from "js/state/actions/policyEditor/editor"

import {
  fetchEnterpriseInformation as fetchAndroidConnections,
  getAndroidApplicationsMetadata,
} from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { canCreateMDMConnection, canViewMDMConnection } from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"

import MobileApplicationsSelectorModal from "js/includes/editors/Policy/PolicyEditor/formComponents/MobileApplicationsSelectorModal"
import MobileApplicationsPolicyModal, {
  pickAppPolicyFields,
} from "js/includes/editors/Policy/PolicyEditor/formComponents/MobileApplicationPolicyModal"
import MobileApplicationsKioskSettings from "./android/MobileApplicationsKioskSettings"
import SystemAppModal from "./android/SystemAppModal"
import DeleteAppModal from "./dialogs/DeleteAppModal"
import { isInputEmptyOrHasValidPackageName } from "./android/util"

import {
  AndroidPolicyAppsApplicationSources as ApplicationSources,
  AndroidPolicyAppsInstallTypeOptions as InstallTypeOptions,
  checkKioskAppExist,
  defaultInheritance,
  getActiveStatusColumn,
  getStatusActions,
  getOverrideStateColumn,
  isInheritedRow,
  isNotOverriddenRow,
  formatApplicationId,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import InheritableRowPolicyItem from "./common/InheritableRowPolicyItem"
import { GroupHeading, FieldRow } from "./SecurityForm"

const {
  typography: { fontWeight },
} = tokens

const permissionType = MDMPermissionType.Android

const canViewConnection = entityId => canViewMDMConnection(permissionType, { entityId })

export const StyledTitleLabel = ({ token, marginTop }) => (
  <Text
    marginBottom={tokens.spacing[2]}
    color="colorTextStrong"
    {...{ token, marginTop }}
    fontWeight={fontWeight.medium}
  />
)

const PlayStoreModeOption = ({ tooltipToken, labelToken }) => (
  <Flex justifyContent="space-between" alignItems="baseline">
    <Text type="body" token={labelToken} />
    <Box>
      <Tooltip label={localized(tooltipToken)}>
        <RegularInfoCircleIcon size="sm" />
      </Tooltip>
    </Box>
  </Flex>
)

const LOCK_ICON = "LockIconSvg"

export const PermissionPolicy = {
  PERMISSION_POLICY_UNSPECIFIED: "PERMISSION_POLICY_UNSPECIFIED",
  PROMPT: "PROMPT",
  GRANT: "GRANT",
  DENY: "DENY",
}

export const DistributionChannels = {
  DISTRIBUTION_CHANNEL_UNSPECIFIED: "DISTRIBUTION_CHANNEL_UNSPECIFIED",
  PUBLIC_GOOGLE_HOSTED: "PUBLIC_GOOGLE_HOSTED",
  PRIVATE_GOOGLE_HOSTED: "PRIVATE_GOOGLE_HOSTED",
  PRIVATE_SELF_HOSTED: "PRIVATE_SELF_HOSTED",
}

const getLockIcon = shouldHideApp => (shouldHideApp ? LOCK_ICON : null)

const columns = isChildPolicy => [
  {
    id: "icon",
    Header: localized("Icon"),
    getImg: ({ iconUrl, applicationSource, shouldHideApp }) => {
      const isSystemApp = applicationSource === ApplicationSources.SYSTEM_APP
      const showSystemAppIcon = isSystemApp || shouldHideApp
      return iconUrl || showSystemAppIcon
        ? { src: showSystemAppIcon ? "/img/logos/android.svg" : iconUrl, size: "46" }
        : null
    },
    accessor: () => "",
    disableSortBy: true,
    minWidth: "46px",
  },
  {
    id: "name",
    Header: localized("Name"),
    accessor: ({ name, packageName, shouldHideApp }) => {
      if (shouldHideApp) {
        return localized("Insufficient permissions")
      }
      return name || packageName
    },
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
    minWidth: "250px",
    maxWidth: "250px",
  },
  {
    id: "publisher",
    Header: localized("Publisher"),
    accessor: ({ shouldHideApp, publisher }) => {
      if (shouldHideApp) {
        return localized("Insufficient permissions")
      }
      return publisher
    },
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "installType",
    Header: localized("Assignment Type"),
    accessor: ({ installType }) => {
      const token = find(propEq("value", installType), InstallTypeOptions)?.labelToken
      return token ? localized(token) : "-"
    },
  },
  {
    id: "appType",
    Header: localized("App type"),
    accessor: ({ shouldHideApp, appTypeLabel }) => {
      if (shouldHideApp) {
        return localized("Insufficient permissions")
      }
      return appTypeLabel
    },
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "packageId",
    Header: localized("Package ID"),
    accessor: ({ shouldHideApp, packageName }) => {
      if (shouldHideApp) {
        return localized("Insufficient permissions")
      }
      return packageName
    },
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "connectionName",
    Header: localized("Android Connection"),
    accessor: ({ connectionName, shouldHideApp }) => {
      if (shouldHideApp) {
        return localized("Insufficient permissions")
      }
      return connectionName
    },
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),

    secondaryIconProps: {
      getIconName: ({ connectionName }) => (!!connectionName ? "RegularInfoCircleIconLight" : null),
      getIconTooltipLabel: ({ enterpriseName }) => enterpriseName,
    },
  },
  ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
]

export const permissionPolicyOptions = [
  {
    value: PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  { value: PermissionPolicy.PROMPT, labelToken: localizationKey("Prompt") },
  { value: PermissionPolicy.GRANT, labelToken: localizationKey("Grant") },
  { value: PermissionPolicy.DENY, labelToken: localizationKey("Deny") },
]

const playStoreModeOptions = [
  {
    value: "PLAY_STORE_MODE_UNSPECIFIED",
    LabelComponent: () => (
      <PlayStoreModeOption
        tooltipToken={localizationKey("Unspecified. Defaults to Allowlist.")}
        labelToken={localizationKey("Play Store Mode Unspecified")}
      />
    ),
  },
  {
    value: "WHITELIST",
    LabelComponent: () => (
      <PlayStoreModeOption
        tooltipToken={localizationKey(
          "Only apps that are in the policy are available and any app not in the policy will be automatically uninstalled from the device.",
        )}
        labelToken={localizationKey("Allowlist")}
      />
    ),
  },
  {
    value: "BLACKLIST",
    LabelComponent: () => (
      <PlayStoreModeOption
        tooltipToken={localizationKey(
          "All apps are available and any app that should not be on the device should be explicitly marked as 'BLOCKED' in the applications policy.",
        )}
        labelToken={localizationKey("Blocklist")}
      />
    ),
  },
]

const defaultPlayStoreMode = playStoreModeOptions[0].value

const untrustedAppsPolicyOptions = [
  {
    value: "UNTRUSTED_APPS_POLICY_UNSPECIFIED",
    labelToken: localizationKey("Unspecified Untrusted Apps Policy"),
  },
  { value: "DISALLOW_INSTALL", labelToken: localizationKey("Disallow Installing") },
  {
    value: "ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY",
    labelToken: localizationKey("Allow Installing in Profile Only"),
  },
  { value: "ALLOW_INSTALL_DEVICE_WIDE", labelToken: localizationKey("Allow Installing Device Wide") },
]

const defaultUntrustedAppsOption = untrustedAppsPolicyOptions[0].value

const PlayStoreAppTypes = {
  PUBLIC_APP: "PUBLIC_APP",
  PRIVATE_APP: "PRIVATE_APP",
  WEB_APP: "WEB_APP",
}

const getAppTypeOptions = memoizeWith(identity, () => [
  {
    value: ApplicationSources.SYSTEM_APP,
    label: localized("System"),
  },
  {
    value: PlayStoreAppTypes.PUBLIC_APP,
    label: localized("Public"),
  },
  {
    value: PlayStoreAppTypes.PRIVATE_APP,
    label: localized("Private"),
  },
  {
    value: PlayStoreAppTypes.WEB_APP,
    label: localized("Web app"),
  },
])

const alertMessageTokens = {
  title: localizationKey("Android MDM Connection"),
  message: () => localized("You need access to an Android connection to view Managed Applications."),
}
const addAndroidConnectionsAlertMessage = {
  title: localizationKey("Android MDM Connection"),
  message: () =>
    localizedWith(
      "Android devices can be linked to one MDM connection at a time. Navigate to your <%link>Android MDM Configuration<%> to add a new connection.",
      {
        link: ({ localizedText }) => (
          <Link href="/#/administration/apps/mdmAndroid" target="_blank" rel="noopener noreferrer">
            {localizedText}
          </Link>
        ),
      },
    ),
}

const getPlayStoreAppType = app => {
  const { packageName, distributionChannel } = app
  // Web app type
  if (packageName.includes(".webapp.")) {
    return PlayStoreAppTypes.WEB_APP
  }
  // Public / Private type
  switch (distributionChannel) {
    case DistributionChannels.PUBLIC_GOOGLE_HOSTED:
      return PlayStoreAppTypes.PUBLIC_APP
    case DistributionChannels.PRIVATE_GOOGLE_HOSTED:
    case DistributionChannels.PRIVATE_SELF_HOSTED:
      return PlayStoreAppTypes.PRIVATE_APP
    default:
      return null
  }
}

// Find non-system apps that are pending metadata update
const getPendingPackagesNames = (appList, defaultConnectionId) => {
  // Generate an object in the form of `{ [connectionId]: { packangeNames, connectionId }}`
  const packageAndConnection = appList.reduce((acc, { packageName, connectionId, updated }) => {
    if (!updated) {
      const currentConnectionId = connectionId ?? defaultConnectionId
      if (!acc[currentConnectionId]) {
        acc[currentConnectionId] = { packageNames: [], connectionId: currentConnectionId }
      }
      acc[currentConnectionId].packageNames.push(packageName)
    }
    return acc
  }, {})
  // Get an array of `{ packageNames, connectionId }`
  return Object.values(packageAndConnection)
}

const getApplicationMetadata = async (apps, androidEnterpriseConnections, defaultConnectionId, locale) => {
  const appsList = values(apps).filter(app => app.applicationSource !== ApplicationSources.SYSTEM_APP)
  const listOfConnectionIds = androidEnterpriseConnections.map(connection => connection.id)
  // Filter out apps whose id don't match any of the androidEnterpriseConnections
  const allowedAppsList = appsList.filter(app =>
    app.connectionId ? listOfConnectionIds.includes(app.connectionId) : app,
  )
  const pending = getPendingPackagesNames(allowedAppsList, defaultConnectionId)

  if (pending.length === 0) return []

  try {
    const metadataApps = await getAndroidApplicationsMetadata({ packageNameList: pending, locale })
    return metadataApps
  } catch (error) {
    ninjaReportError(error)
    return []
  }
}

const shouldHideAction = ({ connectionId }) => !!connectionId && !canViewConnection(connectionId)

const mapApplicationsId = map(evolve({ applicationId: prop("packageName") }))

const cleanSettingsData = reject(isNil)

class MobileApplicationsForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadingAppsMetadata: false,
      loadingAndroidConnections: false,
      appsMetadata: [],
      canAddApplications: false,
      androidEnterpriseConnections: [],
      defaultConnectionId: null,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.loadEnterpriseInfo()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  showAddApp = ({ isSystemApp } = {}) => {
    const { androidEnterpriseConnections, loadingAndroidConnections } = this.state
    const {
      applications: { settingsByApplication },
    } = this.props
    if (isSystemApp) {
      showModal(
        <SystemAppModal
          policyAppsList={values(settingsByApplication)}
          onSave={this.onSaveApp}
          addViaGooglePlay={this.onSaveApp}
          androidEnterpriseConnections={androidEnterpriseConnections}
        />,
        {
          withProvider: true,
        },
      )
    } else {
      showModal(
        <MobileApplicationsSelectorModal
          {...{ loadingAndroidConnections }}
          onApplicationSelected={this.onSaveApp}
          applicationPolicy={values(settingsByApplication)}
          androidEnterpriseConnections={androidEnterpriseConnections}
        />,
        {
          withProvider: true,
        },
      )
    }
  }

  formatApplicationsToRows = (appPolicy = [], metadata = []) => {
    return appPolicy.map(app => {
      const { androidEnterpriseConnections } = this.state
      const appMetadata = find(propEq("packageName", app.packageName), metadata) ?? {}
      const {
        packageName,
        defaultPermissionPolicy,
        applicationSource,
        author,
        title,
        connectionId,
        ...appData
      } = mergeLeft(appMetadata, app)
      const playStoreAppType = appMetadata.packageName ? getPlayStoreAppType(appMetadata) : null

      const permissionPolicyData = find(
        propEq("value", defaultPermissionPolicy ?? PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED),
        permissionPolicyOptions,
      )

      const androidConnection =
        androidEnterpriseConnections && find(propEq("id", connectionId))(androidEnterpriseConnections)

      const appTypeValue = applicationSource === ApplicationSources.SYSTEM_APP ? applicationSource : playStoreAppType
      const appType = find(propEq("value", appTypeValue), getAppTypeOptions())

      const isPrivateOrWebApp =
        appType?.value === PlayStoreAppTypes.PRIVATE_APP || appType?.value === PlayStoreAppTypes.WEB_APP

      const names = applicationSource === ApplicationSources.PLAY_STORE ? { publisher: author, name: title } : {}

      return {
        ...appData,
        ...names,
        playStoreAppType,
        packageName,
        applicationSource,
        updated: true,
        id: packageName,
        permissionPolicyData: permissionPolicyData ? localized(permissionPolicyData.labelToken) : null,
        appTypeLabel: appType?.label,
        connectionName: isPrivateOrWebApp ? androidConnection?.connectionName : null,
        enterpriseName: isPrivateOrWebApp ? androidConnection?.enterpriseDisplayName : "",
        connectionId: app.connectionId,
        // User cannot view app if app actions are hidden
        shouldHideApp: shouldHideAction({ connectionId }),
      }
    })
  }

  onSaveApp = async newApplication => {
    const {
      updatePolicyItem,
      parentPolicy,
      applications: { settingsByApplication },
    } = this.props
    const newApp = this.formatAppToPolicy({ ...newApplication, ...defaultInheritance })
    const uniqueId = formatApplicationId(newApp.packageName)

    await this.loadMetadata(assoc(uniqueId, newApp, settingsByApplication))
    const { appsMetadata, loadingAppsMetadata } = this.state
    const appMetadata = !loadingAppsMetadata && (find(propEq("packageName", newApp.packageName), appsMetadata) ?? {})

    const playStoreAppType = newApp.packageName
      ? getPlayStoreAppType({ packageName: newApp.packageName, distributionChannel: appMetadata?.distributionChannel })
      : null
    const connectionId = includes(playStoreAppType, [PlayStoreAppTypes.WEB_APP, PlayStoreAppTypes.PRIVATE_APP])
      ? newApp.connectionId
      : null

    delete newApp.connectionId

    updatePolicyItem(
      `applications.settingsByApplication.${uniqueId}`,
      parentPolicy,
      // Public apps should not have the entry `connectionId`
      cleanSettingsData({
        connectionId,
        ...newApp,
        ...(appMetadata?.distributionChannel && { distributionChannel: appMetadata.distributionChannel }),
      }),
    )
    showSuccessMessage(
      localized(`{{appName}} was added to the list`, {
        appName: appsMetadata?.title || newApp.packageName,
      }),
    )
  }

  setMountedState = state => {
    this._isMounted && this.setState(state)
  }

  loadMetadata = async policyApps => {
    this.setMountedState({ loadingAppsMetadata: true })
    const { locale } = this.props
    const { androidEnterpriseConnections, defaultConnectionId } = this.state
    const metadata = await getApplicationMetadata(policyApps, androidEnterpriseConnections, defaultConnectionId, locale)
    this.setMountedState({ loadingAppsMetadata: false, appsMetadata: metadata })
  }

  loadEnterpriseInfo = async () => {
    try {
      this.setMountedState({ loadingAndroidConnections: true })
      if (!isAndroidMDMAppEnabled()) {
        this.setMountedState({ androidEnterpriseConnections: [] })
        return
      }
      const data = await fetchAndroidConnections()
      this.setMountedState({ androidEnterpriseConnections: data })
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("No Android Enterprise Connections were found"))
    } finally {
      const { androidEnterpriseConnections } = this.state
      this.setMountedState({
        loadingAndroidConnections: false,
        defaultConnectionId: androidEnterpriseConnections[0]?.id,
      })
      const { loadingAndroidConnections } = this.state
      !loadingAndroidConnections && this.loadMetadata(this.props.applications.settingsByApplication)
    }
  }

  updateSettingPolicy = curry((settingKey, valueKey, value) => {
    const { applications, parentPolicy, updatePolicyItem } = this.props

    const newValue = assocPath(valueKey.split("."), value, applications[settingKey])

    updatePolicyItem(`applications.${settingKey}`, parentPolicy, newValue)
  })

  updateApplicationPolicy = editedApp => {
    const { updatePolicyItem, parentPolicy } = this.props
    updatePolicyItem(
      `applications.settingsByApplication.${formatApplicationId(editedApp.packageName)}`,
      parentPolicy,
      this.formatAppToPolicy(editedApp),
    )
  }

  showEditModal = async app => {
    const { androidEnterpriseConnections } = this.state

    if (app.applicationSource === ApplicationSources.SYSTEM_APP) {
      showModal(
        <SystemAppModal
          {...{ app }}
          onSave={editedApp => {
            this.updateApplicationPolicy(mergeLeft(editedApp, app))
          }}
        />,
        {
          withProvider: true,
        },
      )
      return
    }

    // Private or web apps should only display respective connection
    const appType = getPlayStoreAppType(app)
    const androidConnectionsOptions =
      appType?.includes(PlayStoreAppTypes.PRIVATE_APP) || appType?.includes(PlayStoreAppTypes.WEB_APP)
        ? [androidEnterpriseConnections.find(propEq("id", app.connectionId))]
        : androidEnterpriseConnections

    const {
      applications: {
        settingsByApplication,
        kioskCustomLauncherSettings: { kioskCustomLauncherEnabled = false },
      },
    } = this.props
    const permissionOverrides = map(permission => ({ value: permission, labelText: permission }))(
      app?.permissions || [],
    )

    showModal(
      <MobileApplicationsPolicyModal
        {...{
          appSelected: app,
          delegatedScopesOverrides: androidDelegatedScopesOverrides,
          permissionOverrides,
          onUpdateApplicationPolicy: this.updateApplicationPolicy,
          kioskCustomLauncherEnabled: kioskCustomLauncherEnabled,
          applicationsList: values(settingsByApplication),
          androidEnterpriseConnections: androidConnectionsOptions,
        }}
      />,
      {
        withProvider: true,
      },
    )
  }

  removeApplicationPolicy = selectedApps => {
    const selectedAppsCanUpdate = selectedApps.filter(
      ({ connectionId }) =>
        // Users can't update apps that have hidden actions
        !shouldHideAction({ connectionId }),
    )
    const {
      deletePolicyItem,
      applications: { settingsByApplication },
    } = this.props
    showModal(
      <DeleteAppModal
        selectedApps={mapApplicationsId(selectedAppsCanUpdate)}
        osType="Android"
        updateApplicationsPolicy={apps => {
          for (const { packageName } of selectedAppsCanUpdate) {
            deletePolicyItem(`applications.settingsByApplication.${formatApplicationId(packageName)}`)
          }
        }}
        appPolicy={{ applications: mapApplicationsId(values(settingsByApplication)) }}
      />,
    )
  }

  formatAppToPolicy = appData => {
    const { applicationSource, name, publisher } = appData
    const systemAppData = applicationSource === ApplicationSources.SYSTEM_APP ? { name, publisher } : {}

    const resultApp = pickAppPolicyFields(appData)

    return {
      active: appData.active ?? true,
      disabled: !!appData.disabled,
      ...resultApp,
      ...systemAppData,
      canBeManaged: !!appData.canBeManaged,
      permissionGrantSettings: appData?.permissionGrantSettings || {},
    }
  }

  changeAppStatus = app => {
    const { updatePolicyItem, parentPolicy } = this.props
    updatePolicyItem(
      `applications.settingsByApplication.${formatApplicationId(app.packageName)}`,
      parentPolicy,
      this.formatAppToPolicy({ ...app, active: app.active === false }),
    )
  }

  render() {
    const { applications, isSingleAppKioskEnabled, revertPolicySection, parentPolicy } = this.props
    const { settingsByApplication } = applications
    const {
      loadingAppsMetadata,
      loadingAndroidConnections,
      appsMetadata,
      canAddApplications,
      androidEnterpriseConnections,
    } = this.state
    const isChildPolicy = isNotNil(parentPolicy)
    const alwaysOnVpnPackageName = path(
      ["alwaysOnVpnPackageSettings", "alwaysOnVpnPackage", "packageName"],
      applications,
    )
    return (
      <Box overflow="auto" marginLeft={sizer(6)} paddingRight={sizer(3)}>
        <Box marginBottom={sizer(6)}>
          <TitleGroup
            titleToken={localizationKey("Applications")}
            descriptionToken={localizationKey("Manage and define application settings.")}
          />
        </Box>
        <Tabs
          tabs={[
            {
              path: "managedApps",
              labelToken: localizationKey("Managed Apps"),
              renderer: () => (
                <Box marginTop={tokens.spacing[4]} marginBottom={tokens.spacing[2]}>
                  <StyledTitleLabel token={localizationKey("General")} />
                  <InheritableRowPolicyItem
                    pathToItem="applications.permissionSettings"
                    inheritableItem={applications.permissionSettings}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text type="body" color="colorTextStrong" token={localizationKey("Default Permission Policy")} />
                      <Box className="right">
                        <Select
                          options={permissionPolicyOptions}
                          value={pathOr(
                            PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
                            ["permissionSettings", "defaultPermissionPolicy"],
                            applications,
                          )}
                          triggerAriaLabel={localized("Permission policy")}
                          onChange={this.updateSettingPolicy("permissionSettings", "defaultPermissionPolicy")}
                          alignRight={false}
                        />
                      </Box>
                    </Flex>
                  </InheritableRowPolicyItem>
                  <InheritableRowPolicyItem
                    pathToItem="applications.playStoreSettings"
                    inheritableItem={applications.playStoreSettings}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text type="body" color="colorTextStrong" token={localizationKey("Play Store Mode")} />
                      <Box className="right">
                        <Select
                          options={playStoreModeOptions}
                          value={pathOr(defaultPlayStoreMode, ["playStoreSettings", "playStoreMode"], applications)}
                          triggerAriaLabel={localized("Play store mode")}
                          onChange={this.updateSettingPolicy("playStoreSettings", "playStoreMode")}
                          alignRight={false}
                        />
                      </Box>
                    </Flex>
                  </InheritableRowPolicyItem>
                  <InheritableRowPolicyItem
                    pathToItem="applications.untrustedAppsSettings"
                    inheritableItem={applications.untrustedAppsSettings}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text type="body" color="colorTextStrong" token={localizationKey("Untrusted Apps Policy")} />
                      <Box className="right">
                        <Select
                          options={untrustedAppsPolicyOptions}
                          value={pathOr(
                            defaultUntrustedAppsOption,
                            ["untrustedAppsSettings", "untrustedAppsPolicy"],
                            applications,
                          )}
                          triggerAriaLabel={localized("Untrusted apps policy")}
                          onChange={this.updateSettingPolicy("untrustedAppsSettings", "untrustedAppsPolicy")}
                          alignRight={false}
                        />
                      </Box>
                    </Flex>
                  </InheritableRowPolicyItem>
                  <InheritableRowPolicyItem
                    pathToItem="applications.kioskCustomLauncherSettings"
                    inheritableItem={applications.kioskCustomLauncherSettings}
                    wrapperProps={{ padding: `${tokens.spacing[7]} ${tokens.spacing[2]}` }}
                    noBorder
                  >
                    <Switch
                      labelText={localized("Native multi-app kiosk launcher")}
                      checked={!!path(["kioskCustomLauncherSettings", "kioskCustomLauncherEnabled"], applications)}
                      onChange={this.updateSettingPolicy("kioskCustomLauncherSettings", "kioskCustomLauncherEnabled")}
                      disabled={isSingleAppKioskEnabled}
                      tooltip={
                        isSingleAppKioskEnabled
                          ? localized(
                              "Multi-app kiosk mode cannot be enabled because of a managed application assigned as kiosk mode",
                            )
                          : ""
                      }
                    />
                  </InheritableRowPolicyItem>

                  <GroupHeading
                    textToken={localizationKey("Always-on VPN")}
                    inheritance={{
                      pathToItem: `applications.alwaysOnVpnPackageSettings`,
                      inheritableItem: applications?.alwaysOnVpnPackageSettings ?? defaultInheritance,
                    }}
                  />

                  <FieldRow>
                    <Body color="colorTextStrong">{localized("Configure always-on VPN package")}</Body>
                    <Box className="right" width="298px">
                      <Input
                        ariaLabel={localized("Always-on VPN package")}
                        name="alwaysOnVpn-packageName"
                        placeholder={localized("Input package name")}
                        value={alwaysOnVpnPackageName}
                        onChange={e =>
                          this.updateSettingPolicy(
                            "alwaysOnVpnPackageSettings",
                            "alwaysOnVpnPackage.packageName",
                            e.target.value.trim(),
                          )
                        }
                        errorMessage={
                          isInputEmptyOrHasValidPackageName(alwaysOnVpnPackageName ?? "")
                            ? ""
                            : localized(
                                "The package name must have at least two segments, each segment must start with a letter and all characters must be alphanumeric or an underscore.",
                              )
                        }
                      />
                    </Box>
                  </FieldRow>

                  <FieldRow>
                    <Switch
                      labelText={localized("Block network access if VPN disconnects")}
                      checked={
                        !!path(["alwaysOnVpnPackageSettings", "alwaysOnVpnPackage", "lockdownEnabled"], applications)
                      }
                      onChange={value =>
                        this.updateSettingPolicy(
                          "alwaysOnVpnPackageSettings",
                          "alwaysOnVpnPackage.lockdownEnabled",
                          value,
                        )
                      }
                    />
                  </FieldRow>

                  <StyledTitleLabel token={localizationKey("Managed Applications")} marginTop={tokens.spacing[3]} />

                  {loadingAndroidConnections || androidEnterpriseConnections?.length > 0 ? (
                    <Box height="500px" width="100%">
                      <DataTable
                        isShowSelectedEnabled
                        tableId="mobileapplications-table"
                        pageSizeLimit={20}
                        loading={loadingAppsMetadata || loadingAndroidConnections}
                        rows={this.formatApplicationsToRows(values(settingsByApplication), appsMetadata)}
                        columns={columns(isChildPolicy)}
                        getCustomRowProps={({ connectionId }) => ({
                          disabled: shouldHideAction({ connectionId }),
                          disabledTooltipLabel: localized("You don't have the permissions to manage this application"),
                        })}
                        actions={{
                          primary: [
                            {
                              labelToken: localizationKey("Revert overrides"),
                              action: compose(
                                forEach(app =>
                                  revertPolicySection(
                                    `applications.settingsByApplication.${formatApplicationId(app.packageName)}`,
                                    app,
                                  ),
                                ),
                                prop("selected"),
                              ),
                              hideMultiAction: any(isNotOverriddenRow),
                              hideRowAction: isNotOverriddenRow,
                            },
                            ...getStatusActions(this.changeAppStatus, isChildPolicy),
                            {
                              labelToken: localizationKey("Edit"),
                              action: ({ selected: [app] }) => this.showEditModal(app),
                              hideMultiAction: rows => rows.length > 1,
                              splitAfter: true,
                              hideRowAction: shouldHideAction,
                            },
                            {
                              labelToken: localizationKey("Remove"),
                              hideMultiAction: any(isInheritedRow),
                              hideRowAction: app => isInheritedRow(app) || shouldHideAction(app),
                              isRed: true,
                              action: ({ selected }) => this.removeApplicationPolicy(selected),
                              variant: "danger",
                            },
                          ],
                        }}
                        noRowsToken={
                          canAddApplications
                            ? localizationKey("No packages installed")
                            : localizationKey("Android integration not configured")
                        }
                        globalActionsButton={{
                          buttonProps: {
                            labelToken: localizationKey("Add apps"),
                            variant: "secondary",
                          },
                          actions: [
                            {
                              labelToken: localizationKey("Play Store"),
                              action: this.showAddApp,
                            },
                            {
                              labelToken: localizationKey("System app"),
                              action: () => this.showAddApp({ isSystemApp: true }),
                            },
                          ],
                        }}
                      />
                    </Box>
                  ) : (
                    <Box marginTop={tokens.spacing[4]} marginBottom={tokens.spacing[8]}>
                      {// Can create connections but there are no connections
                      canCreateMDMConnection(MDMPermissionType.Android) &&
                      androidEnterpriseConnections?.length === 0 ? (
                        <AlertMessage titleToken={addAndroidConnectionsAlertMessage.title}>
                          {addAndroidConnectionsAlertMessage.message()}
                        </AlertMessage>
                      ) : (
                        // Cannot view or create any MDM connections
                        <AlertMessage titleToken={alertMessageTokens.title}>
                          {alertMessageTokens.message()}
                        </AlertMessage>
                      )}
                    </Box>
                  )}
                </Box>
              ),
            },
            {
              path: "kioskSettings",
              labelToken: localizationKey("Kiosk Settings"),
              renderer: () => <MobileApplicationsKioskSettings />,
            },
          ]}
        />
      </Box>
    )
  }
}

export default connect(
  ({
    policyEditor: {
      parentPolicy,
      policy: {
        content: { applications },
      },
    },
    session,
    application: { locale },
  }) => ({
    parentPolicy,
    applications,
    isSingleAppKioskEnabled: checkKioskAppExist(applications.settingsByApplication),
    locale,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
    deletePolicyItem: _deletePolicyItem,
    revertPolicySection: _revertPolicySection,
  },
)(MobileApplicationsForm)
