import { memo, useCallback, useMemo, useState } from "react"
import { connect } from "react-redux"
import { count, identity } from "ramda"
import styled from "@emotion/styled"
import { Heading, Body, Button, UnstyledTabsRoot, UnstyledTabsList } from "@ninjaone/components"
import { ArrowRightIcon } from "@ninjaone/icons"
import { spacing } from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { getStartedTabs } from "js/includes/GetStartedPage/gettingStartedData"
import { localizationKey, localized } from "js/includes/common/utils"
import GetStartedFooter from "js/includes/GetStartedPage/GetStartedFooter"
import { setGettingStartedStep } from "js/state/actions/session/gettingStarted"
import { updateGettingStartedStepsStatus } from "js/includes/common/client/gettingStartedSteps"
import FinishTraining from "./FinishTraining"
import GetStartedTabs from "./GetStartedTabs"
import { DAYS, NINETY_ONE_DAYS_THRESHOLD, isOldAccount } from "./utils/accountCreation"

const StyledRightPanelContainer = styled(Box)`
  display: grid;
  grid-template-columns: 30% minmax(400px, 1200px);
  gap: ${spacing[6]};
  padding: 0 ${spacing[6]};
`

const StyledHeader = styled(Box)`
  background-color: ${({ theme }) => theme.colorBackground};
  padding: ${spacing[2]} 0 ${spacing[7]};
  margin: 0 ${spacing[6]} 0;
  position: sticky;

  ${({ showFinishTrainingView }) => {
    if (!showFinishTrainingView)
      return `
      display: grid;
      grid-template-columns: 30% minmax(400px, 1200px);
    `
  }}
`
const StyledContent = styled(UnstyledTabsRoot)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const StyledButton = styled(Button)`
  box-shadow: 0 0 0 0 ${({ theme }) => theme.colorBackgroundCta};
  transform: scale(1);
  animation: ${({ disabled }) => (disabled ? "none" : "pulse 2s infinite")};

  @keyframes pulse {
    0% {
      transform: scale(0.96);
      box-shadow: 0 0 0 0 ${({ theme }) => theme.colorBackgroundCtaWeak};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.96);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`

const GetStarted = ({
  currentStepId,
  firstName,
  gettingStartedSteps,
  setGettingStartedStep,
  stepsCompleted: { finishTraining, ...stepsCompleted },
  userInvitationAcceptedTime,
}) => {
  const [loadingFinishTraining, setLoadingFinishTraining] = useState(false)
  const [showFinishTrainingView, setShowFinishTrainingView] = useState(
    currentStepId === "finishTraining" || isOldAccount(userInvitationAcceptedTime, DAYS, NINETY_ONE_DAYS_THRESHOLD),
  )
  const greetingMessage = showFinishTrainingView
    ? localized("Hi, {{name}}", { name: firstName })
    : localized("Get started")

  const getTab = useCallback(stepId => getStartedTabs[stepId], [])
  const handleTaskCompleted = useCallback(
    async stepIdToChange => {
      const tab = getTab(stepIdToChange)
      const isMeetRequirements = (await tab.validateStepRequirements?.()) ?? true
      if (stepsCompleted[stepIdToChange] || !isMeetRequirements) return
      const delayCompletingTask = tab.stepNumber === 2 || tab.stepNumber > 3 ? 2000 : 0
      setTimeout(async () => {
        await updateGettingStartedStepsStatus({ stepsCompleted, stepIdToChange })
      }, delayCompletingTask)
    },
    [getTab, stepsCompleted],
  )

  const handleFinishTraining = async () => {
    setLoadingFinishTraining(true)
    const stepIdToChange = "finishTraining"
    if (!gettingStartedSteps?.finishTraining) {
      await updateGettingStartedStepsStatus({ stepsCompleted, stepIdToChange })
    }
    setGettingStartedStep(stepIdToChange)
    setShowFinishTrainingView(true)
    setLoadingFinishTraining(false)
  }

  const handleActiveStep = async currentStep => {
    setGettingStartedStep(currentStep)
    setShowFinishTrainingView(false)
    await updateGettingStartedStepsStatus({
      stepsCompleted: {
        ...stepsCompleted,
        finishTraining: gettingStartedSteps ? gettingStartedSteps.finishTraining : false,
      },
      currentStep,
    })
  }

  const isAllStepsCompleted = Object.values(stepsCompleted).every(step => step)
  const renderTabContent = useMemo(
    () =>
      getTab(currentStepId)?.renderer({
        currentStepId,
        onTaskCompleted: handleTaskCompleted,
        stepsCompleted,
      }),
    [currentStepId, getTab, handleTaskCompleted, stepsCompleted],
  )

  return (
    <Flex flex="1" flexDirection="column" justifyContent="space-between">
      <StyledHeader {...{ showFinishTrainingView }}>
        <Flex justifyContent="space-between" alignItems="center" maxWidth="1430px">
          <Heading level={1} color="colorTextStrong">
            {greetingMessage}
          </Heading>
          {!showFinishTrainingView && (
            <>
              {isAllStepsCompleted ? (
                <StyledButton
                  compact
                  disabled={loadingFinishTraining}
                  Icon={ArrowRightIcon}
                  iconPlacement="right"
                  id="fs-getStarted-finishTraining-btn"
                  onClick={handleFinishTraining}
                  labelToken={localizationKey("Finish training")}
                  type="primary"
                  variant="default"
                />
              ) : (
                <Body color="colorTextWeak">
                  {localized("{{number}}/{{ofTotal}} completed", {
                    number: count(identity, Object.values(stepsCompleted)),
                    ofTotal: Object.values(getStartedTabs).length,
                  })}
                </Body>
              )}
            </>
          )}
        </Flex>
      </StyledHeader>

      <StyledContent orientation="vertical" defaultValue={!showFinishTrainingView && currentStepId}>
        <StyledRightPanelContainer>
          <UnstyledTabsList>
            <GetStartedTabs
              handleActiveSteps={handleActiveStep}
              activeStep={currentStepId}
              steps={stepsCompleted}
              finishTraining={showFinishTrainingView || finishTraining}
              deselectTabs={showFinishTrainingView}
            />
          </UnstyledTabsList>
          {showFinishTrainingView ? <FinishTraining /> : <Box paddingRight={spacing[4]}>{renderTabContent}</Box>}
        </StyledRightPanelContainer>
        <GetStartedFooter />
      </StyledContent>
    </Flex>
  )
}

export default memo(
  connect(
    ({
      gettingStarted: { stepsCompleted, currentStepId },
      session: {
        user: {
          firstName,
          content: { gettingStartedSteps },
          userInvitationAcceptedTime,
        },
      },
    }) => ({
      currentStepId,
      firstName,
      gettingStartedSteps,
      stepsCompleted,
      userInvitationAcceptedTime,
    }),
    {
      setGettingStartedStep,
    },
  )(GetStarted),
)
