import moment from "moment"
import { dateSince, localizationKey, localized } from "js/includes/common/utils"
import { either, isNil, propEq, pipe, includes, path, both, complement, __ } from "ramda"
import { qcAgentInstallStatuses, qcDeviceStatuses } from "js/includes/quickConnect/constants/statuses"
import { qcDeviceTypes } from "js/includes/quickConnect/constants/devices"

const deploymentStatusTokenMap = {
  INPROGRESS: localizationKey("In Progress"),
  FAILED: localizationKey("Failed"),
  SUCCESS: localizationKey("Success"),
  PENDING: localizationKey("Pending"),
  UNAVAILABLE: localizationKey("Unavailable"),
  ALREADYINSTALLED: localizationKey("Agent Already Installed"),
}

export const getDeploymentInstallStatus = rowData => {
  const { agentInstallStatus, agentClientId } = rowData

  const { status } = agentInstallStatus || {}

  if (!agentInstallStatus || (!agentClientId && ["", "PENDING", "FAILED"].includes(status || ""))) {
    return ""
  }

  return localized(deploymentStatusTokenMap[status || "PENDING"]).toUpperCase()
}

export const isAgentInstallStatusNull = agentInstallStatus => {
  const isStatusNull = propEq("status", null)
  const eitherNullOrStatusNull = either(isNil, isStatusNull)
  return eitherNullOrStatusNull(agentInstallStatus)
}

export const qcExtractUrlsFromResponse = response => {
  const launchUrl = response?.url
  const macClientUrl = response?.player?.mac?.url
  const { url_32, url_64 } = response?.player?.windows
  const windowsClientUrl = { url_32, url_64 }

  return { launchUrl, macClientUrl, windowsClientUrl }
}

export const qcDetermineDeviceStatus = ({ sessions, status }) =>
  !isNil(sessions) && status === qcDeviceStatuses.ONLINE ? qcDeviceStatuses.CONNECTED : status

export const qcOverrideDeviceStatus = device => {
  const status = qcDetermineDeviceStatus(device)

  return {
    ...device,
    status,
    currentStatus: status,
  }
}

export const isQCDeviceOffline = device => device.currentStatus === qcDeviceStatuses.OFFLINE

export const isQCDeviceOnline = device => device.currentStatus === qcDeviceStatuses.ONLINE

export const isQCDeviceConnected = device => device.currentStatus === qcDeviceStatuses.CONNECTED

export const isQCDeviceFullOfSessions = device => isQCDeviceConnected(device) && device.sessions?.length === 3

export const isQCDeviceMac = ({ deviceType }) => deviceType === qcDeviceTypes.MAC

export const hasOnlyOneQCDeviceSelected = devices => devices.length === 1

export const canConnectToQCDevice = both(isQCDeviceOnline, complement(isQCDeviceFullOfSessions))

export const showQCConnectButtonForSelected = devices =>
  hasOnlyOneQCDeviceSelected(devices) && canConnectToQCDevice(devices[0])

export const isQCDeviceDeployable = either(
  propEq("agentInstallStatus", null),
  pipe(path(["agentInstallStatus", "status"]), includes(__, [qcAgentInstallStatuses.FAILED])),
)

export const qcGetConnectionTimeDisplay = (connstart, connreq) => {
  const _dateSince = dateSince(moment().diff(moment.unix(connstart || connreq)))
  return connstart
    ? _dateSince
    : localized("Connection requested {{connectionRequestTime}} ago", { connectionRequestTime: _dateSince })
}

export const activityStatusOptions = () => [
  {
    id: 5901,
    label: localized("Invitation created"),
  },
  {
    id: 5902,
    label: localized("Invitation expired"),
  },
  {
    id: 5903,
    label: localized("Attempt to use expired invitation blocked"),
  },
  {
    id: 5904,
    label: localized("Invitation updated"),
  },
  {
    id: 5905,
    label: localized("Device deleted"),
  },
  {
    id: 5906,
    label: localized("Agent deployment"),
  },
  {
    id: 5907,
    label: localized("New session started"),
  },
  {
    id: 5908,
    label: localized("Session terminated"),
  },
  {
    id: 5909,
    label: localized("Quick Connect session report submitted"),
  },
]
